import React, {useEffect, useRef, useState} from 'react';

import {Button, Typography} from 'spenda-ui-react';

import {FetchAdaptorDataStatus} from '../../../model/constants/Constants';
import useWorkflowAPI from '../../../services/useWorkflowAPI';
import {IQueueWorkFlowPayload, IWorkflowStatusResponse} from '../../../model/workflow/WorkflowResponse';
import ProgressBar from '../../ProgressBar';
import {ARDialogTemplate} from '../ARDialogTemplate';

interface IImportWorkflowsModalProps {
  open: boolean;
  onSuccess: () => void;
  message?: string;
  isFooter?: boolean;
  isLoading?: boolean;
  importWorkflowId?: number;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  queuePayload?: Partial<IQueueWorkFlowPayload>;
}
export const ImportWorkflowsModal = (props: IImportWorkflowsModalProps) => {
  // Props
  const {
    open,
    isLoading,
    onSuccess,
    isFooter,
    message,
    importWorkflowId,
    queuePayload,
    onPrimaryClick,
    onSecondaryClick,
  } = props;

  const progressTimer = useRef<NodeJS.Timeout | null>(null);
  const workflowStatusTimer = useRef<NodeJS.Timeout | null>(null);

  // State
  const [importCOARequestPercentage, setImportCOARequestPercentage] = useState<any>(0);
  const [importCOARequestStatus, setImportCOARequestStatus] = useState<string>(FetchAdaptorDataStatus.NOTSTARTED);

  // APIs
  const {checkWorkflowStatus, queueWorkflow} = useWorkflowAPI();

  useEffect(() => {
    if (importWorkflowId) {
      setImportCOARequestStatus(FetchAdaptorDataStatus.STARTED);
      loadingPercentageCalculator();
      checkWorkflowStatusFun(importWorkflowId);
    }
  }, [importWorkflowId]);

  useEffect(() => {
    queuePayload && startWorkFlow(queuePayload);
  }, [queuePayload]);

  useEffect(() => {
    return () => {
      workflowStatusTimer.current && clearTimeout(workflowStatusTimer.current);
      progressTimer.current && clearInterval(progressTimer.current);
    };
  }, [workflowStatusTimer, progressTimer]);

  // Functions
  const checkWorkflowStatusFun = (WorkflowId: number) => {
    checkWorkflowStatus(WorkflowId).then((data: IWorkflowStatusResponse) => {
      if (workflowStatusTimer.current) {
        clearInterval(workflowStatusTimer.current);
      }
      if (data.Status === 'Complete') {
        setImportCOARequestStatus(FetchAdaptorDataStatus.SUCCESSFUL);
        setImportCOARequestPercentage(100);
        onSuccess();
      } else {
        workflowStatusTimer.current = setInterval(() => {
          checkWorkflowStatusFun(WorkflowId);
        }, 5000);
      }
    });
  };

  const startWorkFlow = async (queuePayload: Partial<IQueueWorkFlowPayload>) => {
    setImportCOARequestStatus(FetchAdaptorDataStatus.STARTED);
    setImportCOARequestPercentage(0);
    loadingPercentageCalculator();
    const res = await queueWorkflow(queuePayload);

    if (res?.IsComplete) {
      setImportCOARequestStatus(FetchAdaptorDataStatus.SUCCESSFUL);
      setImportCOARequestPercentage(100);
      onSuccess();
    } else {
      workflowStatusTimer.current = setTimeout(() => {
        res?.WorkflowID && checkWorkflowStatusFun(res.WorkflowID);
      }, 5000);
    }
  };

  const updateProgress = (value: number) => {
    setImportCOARequestPercentage((state: number) => {
      if (value < 99) {
        return value;
      }
      return state;
    });
  };

  let loadingPercentageCalculator = async () => {
    if (progressTimer.current) {
      clearInterval(progressTimer.current);
    }
    let percentage = 0;
    progressTimer.current = setInterval(() => {
      percentage = percentage + 1;
      updateProgress(percentage);
    }, 500);
  };

  return (
    <ARDialogTemplate
      isFullScreen
      dialogContentClass="!h-full"
      bodyClass="!h-full !p-0"
      dialogProps={{
        size: 'xs',
        open: open,
        className: 'sm:!min-w-[565px] sm:!max-w-[565px] sm:!h-[412px]',
      }}
      body={
        <div className="relative !mt-0 h-full pb-16 sm:mt-24">
          <Typography
            variant="small"
            className="mb-12 px-6 pt-12 text-center text-base font-normal text-black-800 sm:mb-20 sm:pt-24 sm:text-lg"
          >
            {message}
          </Typography>
          <div className="mb-6 flex px-6 sm:mb-12 sm:px-12">
            {importCOARequestStatus === FetchAdaptorDataStatus.SUCCESSFUL && (
              <p id="simple-modal-description" className={`p-7 text-center text-base`}>
                Imported successfully!
              </p>
            )}
            {importCOARequestStatus === FetchAdaptorDataStatus.STARTED && (
              <ProgressBar isWithoutText bgcolor={'hsl(var(--primary))'} completed={importCOARequestPercentage} />
            )}
            {importCOARequestStatus === FetchAdaptorDataStatus.FAILED && (
              <p id="simple-modal-description" className={`p-7 text-center text-base`}>
                Import Failed!
              </p>
            )}
          </div>
          {isFooter && (
            <div className="absolute bottom-0 flex h-[52px] w-full flex-row items-center justify-between gap-2.5 rounded-[6px] bg-[#EFEFEF] px-2.5">
              <Button
                data-autoid={`btnStayOnThisPage`}
                className="bg-white"
                onClick={onSecondaryClick}
                variant="outlined"
                color="primary"
              >
                Stay on this page
              </Button>
              <Button
                disabled={false}
                loading={isLoading}
                variant="filled"
                color="primary"
                data-autoid={`btnContinueOnboarding`}
                onClick={onPrimaryClick}
              >
                Continue Onboarding
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};
