import React, {useEffect, useState} from 'react';
import {Typography} from 'spenda-ui-react';
import {ARTable} from '../AccountsReceivable/ARTable';
import Delete from '@material-ui/icons/Delete';
import {SelectableButton} from '../AccountsReceivable/AdvanceFilter';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';
import {SelectCOADropdown} from '../AccountsReceivable/required-attention/SelectCOADropdown';
import {IGetInventoryTemplates, IMarketPlaceInventoryTemplates} from '../../model/OnboardingV1Step';
import {useOnboarding} from '../../services/useOnboarding';
import clsx from 'clsx';
import {usePSBLHook} from '../../hooks/useARHook';
import {defaultMarketPlacePath} from '../../config';

enum CurrentScreenEnum {
  SelectableButtonView = 'SelectableButtonView',
  EditableTableView = 'EditableTableView',
}

interface ISetupInventoryProps {
  handleNext: () => void;
}

export const SetUpInventory = (props: ISetupInventoryProps) => {
  // Hooks
  const {getSupplierByMarketplacePath} = usePSBLHook();
  const marketplaceID = getSupplierByMarketplacePath(defaultMarketPlacePath)?.MarketplaceID;

  // Props
  const {handleNext} = props;

  // State
  const [currentState, setcurrentState] = useState<CurrentScreenEnum>(CurrentScreenEnum.SelectableButtonView);
  const [inventoryData, setInventoryData] = useState<IGetInventoryTemplates>();
  const [selectedTitles, setSelectedTitles] = useState<IMarketPlaceInventoryTemplates[]>([]);

  // API
  const {getInventoryTemplate} = useOnboarding();

  useEffect(() => {
    const fetchData = async () => {
      if (marketplaceID) {
        await getSetupInventory(marketplaceID);
      }
    };
    fetchData();
  }, [marketplaceID]);

  // Function
  const getSetupInventory = async (marketplaceID: number) => {
    try {
      const response = await getInventoryTemplate(marketplaceID);
      setInventoryData(response?.value);
    } catch {}
  };

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white">
      {currentState === 'SelectableButtonView' ? (
        <SelectableButtonView
          onScreenChange={setcurrentState}
          inventoryData={inventoryData}
          selectedTitles={selectedTitles}
          setSelectedTitles={setSelectedTitles}
        />
      ) : (
        <EditableTableView handleNext={handleNext} selectedTitles={selectedTitles} />
      )}
    </div>
  );
};

interface ISelectableButtonViewProps {
  onScreenChange: (currentScreen: CurrentScreenEnum) => void;
  inventoryData?: IGetInventoryTemplates;
  selectedTitles: IMarketPlaceInventoryTemplates[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<IMarketPlaceInventoryTemplates[]>>;
}

const SelectableButtonView = (props: ISelectableButtonViewProps) => {
  //Props
  const {inventoryData, onScreenChange, selectedTitles, setSelectedTitles} = props;

  useEffect(() => {
    const defaultSelected = (inventoryData?.marketplaceInventoryTemplates || []).filter(
      item => item.shortDescription === 'Parts',
    );
    setSelectedTitles(prev => [...prev, ...defaultSelected]);
  }, [inventoryData]);

  const handleClick = (inventoryData: IMarketPlaceInventoryTemplates) => {
    setSelectedTitles(prevTitles => {
      const isAlreadySelected = prevTitles.some(title => title.shortDescription === inventoryData.shortDescription);

      if (isAlreadySelected) {
        if (inventoryData.shortDescription === 'Parts') {
          return prevTitles;
        }
        return prevTitles.filter(title => title.shortDescription !== inventoryData.shortDescription);
      } else {
        return [...prevTitles, inventoryData];
      }
    });
  };

  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-24">
      <div className={`mx-auto flex h-full w-full max-w-[622px] flex-col justify-between overflow-y-auto`}>
        <div>
          <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
            What do you use your Capricorn trade account for?
          </p>
          <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            We’ve compiled a list of common inventory items for you to consider. Please select the items that are
            relevant to your situation. Note that "Parts" is mandatory, so you won’t be able to deselect those options.
          </p>
          <p className={`mt-4 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            On the next screen, you'll have the opportunity to create unique inventory items, as well as edit or remove
            any selected items.
          </p>
          <div className="mb-5 mt-10 grid w-full grid-cols-2 gap-y-5 sm:w-[520px] sm:!max-w-[520px] sm:grid-cols-3 sm:gap-x-5">
            {inventoryData?.marketplaceInventoryTemplates?.map((item, index) => {
              const isSelected = selectedTitles.some(title => title.shortDescription === item.shortDescription);
              return (
                <div className="flex w-full justify-center" key={index}>
                  <SelectableButton
                    label={item.shortDescription}
                    isSelected={isSelected}
                    onClick={() => handleClick(item)}
                    className={clsx(
                      'h-[60px] w-[148px] bg-primary-header text-center text-sm  text-primary hover:border-primary sm:h-[40px] sm:w-[165px]',
                      {
                        'border-solid !border-primary': isSelected,
                      },
                    )}
                    dataAutoId={`btnSelectInventoryItem-${item.shortDescription}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter isChatWidget onPrimaryClick={() => onScreenChange(CurrentScreenEnum.EditableTableView)} />
    </div>
  );
};

interface IEditableTableView {
  handleNext: () => void;
  selectedTitles: IMarketPlaceInventoryTemplates[];
}
interface ISelectedInventoryValue {
  marketplaceID: number;
  inventoryCode: string;
  shortDescription: string;
  expenseAccount?: string;
  isNewRow?: boolean;
}

const EditableTableView = (props: IEditableTableView) => {
  // State
  const [localSelectedTitles, setLocalSelectedTitles] = useState<ISelectedInventoryValue[]>(props.selectedTitles || []);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  // Props
  const {handleNext, selectedTitles} = props;

  // API
  const {saveInventories, isLoading} = useOnboarding();

  useEffect(() => {
    setLocalSelectedTitles(selectedTitles);
  }, [selectedTitles]);

  useEffect(() => {
    if (!isValidField()) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [localSelectedTitles]);

  const handleDropdownChange = (rowKey: number, value: string) => {
    setLocalSelectedTitles(prevState => {
      const updatedRows = [...prevState];
      updatedRows[rowKey] = {...updatedRows[rowKey], expenseAccount: value};
      return updatedRows;
    });
  };

  const handleAddRow = () => {
    const newRow = {
      inventoryCode: '',
      shortDescription: '',
      expenseAccount: '',
      marketplaceID: 0,
      isNewRow: true,
    };

    setLocalSelectedTitles(prevRows => [...prevRows, newRow]);
  };

  const handleInputChange = (index: number, field: keyof ISelectedInventoryValue, value: string) => {
    setLocalSelectedTitles(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {...updatedRows[index], [field]: value};
      return updatedRows;
    });
  };

  const handleDeleteRow = (index: number) => {
    setLocalSelectedTitles(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });
  };

  const isValidField = () => {
    const hasEmptyFields = localSelectedTitles.some(
      item => !item.inventoryCode.trim() || !item.shortDescription.trim() || !(item.expenseAccount?.trim() || ''),
    );
    if (hasEmptyFields) {
      return false;
    }
    return true;
  };

  const handleInventoryNext = async () => {
    setErrorMessage('');
    if (!isValidField()) {
      setErrorMessage('Please fill in all required fields before proceeding.');
      return;
    }

    const seenItemCodes = new Set();
    const seenDescriptions = new Set();

    const duplicateItemCode = localSelectedTitles.find(item => {
      const lowerCasedItemCode = item.inventoryCode.trim().toLowerCase();
      if (seenItemCodes.has(lowerCasedItemCode)) return true;
      seenItemCodes.add(lowerCasedItemCode);
      return false;
    });

    if (duplicateItemCode) {
      setErrorMessage('Duplicate item code found. Please make sure all item codes are unique.');
      return;
    }

    const duplicateDescription = localSelectedTitles.find(item => {
      const lowerCasedDescription = item.shortDescription.trim().toLowerCase();
      if (seenDescriptions.has(lowerCasedDescription)) return true;
      seenDescriptions.add(lowerCasedDescription);
      return false;
    });

    if (duplicateDescription) {
      setErrorMessage('Duplicate inventory item found. Please make sure all inventory items are unique.');
      return;
    }

    const formattedData = {
      inventories: localSelectedTitles.map(item => ({
        inventoryCode: item.inventoryCode,
        shortDescription: item.shortDescription,
        expenseAccount: item.expenseAccount || '',
      })),
    };

    try {
      await saveInventories(formattedData);
      handleNext();
    } catch (error) {}
  };

  const inventorySetupColumns = [
    {
      title: 'Item codes',
      key: 'itemCodes',
      align: 'left',
      width: '20%',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-1',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        return rowData.isNewRow ? (
          <input
            type="text"
            value={rowData?.inventoryCode || ''}
            onChange={e => handleInputChange(index, 'inventoryCode', e.target.value)}
            className="w-full rounded-md border border-gray-300 p-[10px] font-medium text-black-900"
          />
        ) : (
          <Typography data-autoid={`lblInventoryItems`} className=" p-[10px] font-medium text-black-900">
            {rowData?.inventoryCode}
          </Typography>
        );
      },
    },
    {
      title: 'Inventory items',
      key: 'inventoryItems',
      width: '30%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-1',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        return rowData.isNewRow ? (
          <input
            type="text"
            value={rowData?.shortDescription || ''}
            onChange={e => handleInputChange(index, 'shortDescription', e.target.value)}
            className="w-full rounded-md border border-gray-300 p-[10px] font-medium text-black-900"
          />
        ) : (
          <Typography data-autoid={`lblInventoryItems`} className=" p-[10px] font-medium text-black-900">
            {rowData?.shortDescription}
          </Typography>
        );
      },
    },
    {
      title: 'Purchase account',
      key: 'purchaseAccount',
      width: '50%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-0',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        return (
          <div className="p-1">
            <SelectCOADropdown
              lowTouchOnboarding={true}
              onChange={value => handleDropdownChange(index, value)}
              value={localSelectedTitles[index].expenseAccount}
              defaultPlaceholder="Select a purchase account"
              className="!border-none  !outline-none disabled:!bg-transparent"
              inputProps={{
                name: 'purchaseAccountdropdown',
              }}
            />
          </div>
        );
      },
    },
    {
      key: 'deleteItem',
      width: '10%',
      align: 'left',
      columClassName: 'bg-white',
      rowClassName: 'p-2.5 bg-white border-b border-white',
      rowRenderer: (rowData: ISelectedInventoryValue, index: number) => {
        const firstPartsIndex = localSelectedTitles.findIndex(item => item.shortDescription === 'Parts');
        if (rowData.shortDescription === 'Parts' && index === firstPartsIndex) {
          return <></>;
        }
        return (
          <Delete
            data-autoid={`btnDeleteLine`}
            className="cursor-pointer text-[#CCCCCC]"
            onClick={() => handleDeleteRow(index)}
          />
        );
      },
    },
  ];
  return (
    <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-16 font-poppins">
      <div
        className={`mx-auto flex h-full w-full flex-col justify-between overflow-hidden pb-10 sm:max-w-[622px] sm:pb-0`}
      >
        <div className="flex h-[80%] flex-col sm:h-full">
          <div className={`mx-auto max-w-[622px] align-middle`}>
            <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
              Let’s get your inventory set up
            </p>
            <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              You'll need to make sure you have inventory items set up for everything you usually purchase on your
              Capricorn trade account. To get you started, we've got a list of suggestions that we can create for you
              below..
            </p>
            <p className={`mt-4 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
              Don't worry, if you don't get this right the first time we can import more from your financial system
              later
            </p>
          </div>
          <div className={`mb-0 mt-6 flex h-full w-full !max-w-[606px] flex-col items-start overflow-x-auto bg-white`}>
            <ARTable
              conatinerClass={`${localSelectedTitles.length <= 4 ? '!overflow-y-visible' : '!overflow-y-auto'} !w-[606px] !h-auto !px-0`}
              isLoading={false}
              columns={inventorySetupColumns}
              rows={localSelectedTitles}
              dataAutoId="inventoryAccountSetup"
              getRowDataAutoId={rowData => `rowInventorySetup-${rowData?.ID}`}
            />
            {errorMessage && (
              <Typography
                className="inline w-fit overflow-ellipsis font-medium text-error"
                data-autoid="txtErrorMessage"
              >
                {errorMessage}
              </Typography>
            )}
            <Typography
              className="inline w-fit cursor-pointer overflow-ellipsis font-medium text-primary"
              data-autoid="lnkAddNewInventoryItem"
              onClick={handleAddRow}
            >
              + Add New inventory item
            </Typography>
          </div>
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter
        isChatWidget
        isDisabled={isDisabled}
        onPrimaryClick={handleInventoryNext}
        isLoading={isLoading}
      />
    </div>
  );
};
