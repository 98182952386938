import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {APFooter} from '.././APFooter';
import {IMasterAdaptor} from '../../../model/MasterAdaptor';
import {PrimaryButton} from '../../buttons/DefaultButtons';
import LoadingIndicator from '../../ui/LoadingIndicator';
import useWorkflowAPI from '../../../services/useWorkflowAPI';
import {
  APOnboardingSteps,
  DatTypes,
  FetchAdaptorDataStatus,
  FinancialAdaptors,
  FinancialAdaptorsType,
  LowTouchPsblOnboardingSteps,
  PsblOnboardingSteps,
} from '../../../model/constants/Constants';
import useSynkAPI from '../../../services/useSynkAPI';
import {ISynkIsAuthorizedResponse} from '../../../model/synkd/SynkAuthorizeResponse';
import {IWorkflowStatusResponse} from '../../../model/workflow/WorkflowResponse';
import moment from 'moment';
import ProgressBar from '../../ProgressBar';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import {STextField} from '../../inputs/STextField';
import {SkipAutorisationModal} from './SkipAuthorisationModal';
import {Toast} from '../../../utils/Toast';
import XeroLogo from '../../../assets/png/xero-logo.png';
import QBOLogo from '../../../assets/png/QuickBooksOnline-logo.png';
import MyobLogo from '../../../assets/svg/MyObAccountRightLive-logo.svg';
import MyobLogoPsbl from '../../../assets/svg/Myob_logo.svg';
import {Button, Typography} from 'spenda-ui-react';
import {websiteId} from '../../../config';
import clsx from 'clsx';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../../buttons/PSBLOnboardingFooter';

export interface IFinancialAdaptorProps {
  accounts?: IMasterAdaptor[];
  selectedAccount?: IMasterAdaptor;
  onSelectAccountingSystem: (account: IMasterAdaptor) => void;
  isAuthorized?: boolean;
  isLoading?: boolean;
  synkConnect: () => void;
  onBoardingAccountRedirectUrl?: string;
  onboardingAccount?: string;
  userName?: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setIsAuthorized: Dispatch<SetStateAction<boolean>>;
  setOrganizationName: Dispatch<SetStateAction<string>>;
  isAPOnboardingV1?: boolean;
  organizationName?: string;
  handleSkipAuthorization: () => void;
  handleNext: () => void;
  currentActiveTab?: APOnboardingSteps | PsblOnboardingSteps | LowTouchPsblOnboardingSteps;
  isPsblOnboarding?: boolean;
  lmiCommercialRelease92671?: boolean;
  psblV22LowTouchOnboarding92923?: boolean;
}

export const FinancialAdaptor = (props: IFinancialAdaptorProps) => {
  const {
    accounts,
    selectedAccount,
    onSelectAccountingSystem,
    isAuthorized,
    isLoading,
    synkConnect,
    onBoardingAccountRedirectUrl,
    onboardingAccount,
    userName,
    setIsLoading,
    setIsAuthorized,
    setOrganizationName,
    organizationName,
    handleSkipAuthorization,
    handleNext,
    currentActiveTab,
    isPsblOnboarding,
    lmiCommercialRelease92671,
    psblV22LowTouchOnboarding92923,
  } = props;

  // const {supplierId} = useParams<{supplierId?: string}>();
  const origin = window.location.origin;
  const financialAdaptorName =
    selectedAccount && FinancialAdaptors[selectedAccount.Name as keyof typeof FinancialAdaptors];
  const onboardingPath = isPsblOnboarding
    ? `supplier/psbl/onboarding/${financialAdaptorName}`
    : `pay/onboardingv1/${financialAdaptorName}`;
  const xeroAuthorizeRedirectLink = `${origin}/${onboardingPath}`;
  const [selectedAdaptorId, setSelectedAdaptorId] = useState<number | undefined>();

  // MYOB Authentication Screen States
  const [isOrgLoading, setIsOrgLoading] = useState(false);
  const [organisationsList, setOrganisationsList] = useState<any[]>([]);
  const [showSelectOrganisations, setShowSelectOrganisations] = useState(false);
  const [organisationData, setOrganisationData] = useState({
    OrganisationId: '',
    OrganisationName: '',
  });
  const [orgUserName, setOrgUserName] = useState('');
  const [password, setPassword] = useState('');
  ///////////////////////////////////////////////
  const {synkDataRead, checkWorkflowStatus} = useWorkflowAPI();
  const {synkAuthorize, synkIsAuthorize, getOrgName, onAuthorised} = useSynkAPI();
  const [purchaseInvoiceRequestStatus, setPurchaseInvoiceRequestStatus] = useState<string>(
    FetchAdaptorDataStatus.NOTSTARTED,
  );
  const [purchaseInvoiceRequestPercentage, setPurchaseInvoiceRequestPercentage] = useState<any>(0);
  const [isSkipAuthorization, setIsSkipAuthorization] = useState<Boolean>(false);
  let timer: ReturnType<typeof setInterval>;
  let workflowStatusTimer: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (selectedAccount && onboardingAccount && !isAuthorized) {
      setIsLoading(true);
      //check if is authorized else authorize again
      connectAdaptor(selectedAccount);
    }
  }, [onBoardingAccountRedirectUrl, selectedAccount]);

  useEffect(() => {
    if (
      isPsblOnboarding &&
      psblV22LowTouchOnboarding92923 &&
      isAuthorized &&
      currentActiveTab === LowTouchPsblOnboardingSteps.ConfigureFinancialAdaptor &&
      purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.NOTSTARTED &&
      organizationName
    ) {
      handleNext();
    }
  }, [
    isAuthorized,
    purchaseInvoiceRequestStatus,
    organizationName,
    psblV22LowTouchOnboarding92923,
    isPsblOnboarding,
    currentActiveTab,
  ]);

  const submitMyobOrg = () => {
    if (
      selectedAccount &&
      selectedAccount.ID &&
      organisationData &&
      organisationData.OrganisationName &&
      organisationData.OrganisationId
    ) {
      setIsOrgLoading(true);
      onAuthorised(
        selectedAccount.ID,
        organisationData.OrganisationName,
        orgUserName,
        password,
        organisationData.OrganisationId,
      )
        .then(async res => {
          if (res.IsSuccess) {
            setIsLoading(true);
            await getChartsOfAccounts();
            setIsLoading(false);
            setOrganizationName(res?.OrganisationName);
            setIsAuthorized(true);
            setShowSelectOrganisations(false);
          }
          setIsOrgLoading(false);
        })
        .catch(_e => {
          setIsOrgLoading(false);
        });
    } else {
      Toast.error('Please Select Organisation');
    }
  };

  const updateProgress = (value: number) => {
    setPurchaseInvoiceRequestPercentage((state: number) => {
      if (value < 99) {
        return value;
      }
      return state;
    });
  };

  const checkWorkflowStatusFun = (PurchaseInvoiceRequestWorkflowId: number) => {
    if (PurchaseInvoiceRequestWorkflowId) {
      checkWorkflowStatus(PurchaseInvoiceRequestWorkflowId).then((data: IWorkflowStatusResponse) => {
        if (data.Status === 'Complete') {
          clearTimeout(workflowStatusTimer);
          clearInterval(timer);
          setPurchaseInvoiceRequestStatus(FetchAdaptorDataStatus.SUCCESSFUL);
          setPurchaseInvoiceRequestPercentage(100);
        } else {
          workflowStatusTimer = setTimeout(() => {
            checkWorkflowStatusFun(PurchaseInvoiceRequestWorkflowId);
          }, 5000);
        }
      });
    }
  };

  let loadingPercentageCalculator = async () => {
    clearInterval(timer);
    let percentage = 0;
    timer = setInterval(() => {
      percentage = percentage + 1;
      updateProgress(percentage);
    }, 500);
  };

  const getPurchaseInvoices = async () => {
    if (selectedAccount) {
      setPurchaseInvoiceRequestStatus(FetchAdaptorDataStatus.STARTED);
      setPurchaseInvoiceRequestPercentage(0);
      loadingPercentageCalculator();
      let PurchaseInvoice: {Value: {WorkflowID: number | undefined; IsComplete: any}};
      const purchaseInvoices = await synkDataRead(
        true,
        'Read',
        selectedAccount.Name!,
        selectedAccount.ID!,
        DatTypes.PurchaseInvoice,
        29,
        true,
        isPsblOnboarding
          ? moment().subtract(1, 'month').startOf('month').toISOString()
          : moment().subtract(3, 'years').toISOString(),
      );
      PurchaseInvoice = purchaseInvoices;
      if (PurchaseInvoice?.Value?.IsComplete) {
        setPurchaseInvoiceRequestStatus(FetchAdaptorDataStatus.SUCCESSFUL);
        setPurchaseInvoiceRequestPercentage(100);
        clearInterval(timer);
      } else {
        setPurchaseInvoiceRequestStatus(FetchAdaptorDataStatus.FAILED);
        workflowStatusTimer = setTimeout(() => {
          PurchaseInvoice?.Value?.WorkflowID && checkWorkflowStatusFun(PurchaseInvoice.Value.WorkflowID);
        }, 5000);
      }
    }
  };

  const getChartsOfAccounts = () => {
    if (isPsblOnboarding && psblV22LowTouchOnboarding92923) return;
    return synkDataRead(false, 'Read', selectedAccount?.Name!, selectedAccount?.ID!, DatTypes.Accounts, 29);
  };

  const authorizeAccount = async () => {
    if (
      onBoardingAccountRedirectUrl &&
      onBoardingAccountRedirectUrl != '' &&
      userName &&
      selectedAccount &&
      onboardingAccount
    ) {
      synkAuthorize(
        userName,
        selectedAccount.Name!,
        onBoardingAccountRedirectUrl,
        xeroAuthorizeRedirectLink!,
        websiteId,
      )
        .then(async data => {
          if (data.IsSuccess) {
            if (
              selectedAccount.ID &&
              selectedAccount.Name === FinancialAdaptorsType.MyObAccountRightLive &&
              !data.OrganisationName
            ) {
              getOrgName(selectedAccount.ID).then(data => {
                setOrganisationsList(data?.AuthOrganisationDetails);
                data?.AuthOrganisationDetails &&
                  data?.AuthOrganisationDetails?.length &&
                  setOrganisationData(data?.AuthOrganisationDetails[0]);
                setShowSelectOrganisations(true);
              });
            } else {
              setIsLoading(true);
              await getChartsOfAccounts();
            }
            setIsAuthorized(true);
            setOrganizationName(data.OrganisationName || '');
            setIsLoading(false);
          } else {
            setIsAuthorized(false);
            setIsLoading(false);
          }
        })
        .catch(async () => {
          setIsLoading(false);
          setIsAuthorized(false);
        });
    }
  };

  const connectAdaptor = (account: IMasterAdaptor) => {
    synkIsAuthorize(account.Name!, account.ID!, account.MasterAdaptorID!).then(
      async (response: ISynkIsAuthorizedResponse) => {
        setIsLoading(true);
        if (!response.IsAuthorized) {
          authorizeAccount();
        } else if (
          account.ID &&
          account.Name === FinancialAdaptorsType.MyObAccountRightLive &&
          !response?.TokenDetails?.OrgName
        ) {
          getOrgName(account.ID).then(data => {
            setIsLoading(true);
            setOrganisationsList(data?.AuthOrganisationDetails);
            data?.AuthOrganisationDetails &&
              data?.AuthOrganisationDetails?.length &&
              setOrganisationData(data?.AuthOrganisationDetails[0]);
            setShowSelectOrganisations(true);
            setIsLoading(false);
          });
        }
        setOrganizationName(response?.TokenDetails?.OrgName || '');
      },
    );
  };

  const renderAdaptorImage = (account: IMasterAdaptor) => {
    if (account) {
      switch (account.Name) {
        case FinancialAdaptorsType.MyObAccountRightLive:
          return (
            <>
              <img
                className={'mt-8'}
                style={{width: isPsblOnboarding ? '46px' : '102px', height: isPsblOnboarding ? '46px' : '44px'}}
                src={isPsblOnboarding ? MyobLogoPsbl : MyobLogo}
                alt="Account"
                data-autoid="imgMyob"
              />
              <span
                className={clsx(
                  'mt-2 font-poppins text-sm font-semibold',
                  isPsblOnboarding ? 'text-black-800' : 'text-primary',
                )}
              >
                MYOB
              </span>
            </>
          );
        case FinancialAdaptorsType.QuickBooksOnline:
          return (
            <>
              <img
                className={'mt-7'}
                style={{width: '46px', height: '46px'}}
                src={QBOLogo}
                alt="Account"
                data-autoid="imgQuickBook"
              />
              <span
                className={clsx(
                  'mt-3 font-poppins text-sm font-semibold',
                  isPsblOnboarding ? 'text-black-800' : 'text-primary',
                )}
              >
                QBO
              </span>
            </>
          );
        case FinancialAdaptorsType.Xero:
          return (
            <>
              <img
                className={'mt-5'}
                style={{width: '57px', height: '60px'}}
                src={XeroLogo}
                alt="Account"
                data-autoid="imgXero"
              />
              <span
                className={clsx(
                  'font-poppins text-sm font-semibold',
                  isPsblOnboarding ? 'text-black-800' : 'text-primary',
                )}
              >
                Xero
              </span>
            </>
          );
      }
    }
  };

  const renderMyOBOrgSelection = () => {
    return (
      <div>
        <p
          id="simple-modal-description"
          className={`simple-modal-title mt-2 pb-3 text-left font-poppins text-[24px] font-light text-black-800`}
        >
          Select the organisation you wish to connect
        </p>
        <div className="space-between flex ">
          <div className={`mr-8 min-h-[315px] flex-1 rounded-lg bg-primary/5`}>
            <div className={`flex flex-col items-start`}>
              <span className="ml-4 mt-4 items-start font-poppins text-base font-medium text-spenda-labeltext">
                Select organisation:
              </span>
              <RadioGroup
                id="org"
                onChange={e => {
                  const selectedOrg = organisationsList.find(org => org?.OrganisationId === e.target.value);
                  selectedOrg && setOrganisationData(selectedOrg);
                }}
                className="ml-4"
                value={organisationData?.OrganisationId}
              >
                {Array.isArray(organisationsList) &&
                  organisationsList.map((org: any | undefined) => {
                    return (
                      <FormControlLabel
                        key={org?.OrganisationId}
                        control={<Radio color="secondary" size="small" />}
                        className="ml-4 mt-4 font-poppins text-base font-medium text-black-800"
                        label={
                          <span className="font-poppins text-base font-medium text-black-800">
                            {org?.OrganisationName}
                          </span>
                        }
                        value={org?.OrganisationId}
                      />
                    );
                  })}
              </RadioGroup>
            </div>
          </div>
          <div className="flex-1">
            <p className={`text-left font-poppins text-base font-medium text-black-800`}>
              Please enter your organisations login details.
            </p>
            <div className={`mt-8`}>
              <STextField
                v2
                fullWidth
                id="username"
                name="username"
                variant="outlined"
                label="Username"
                placeholder="Username"
                InputProps={{
                  inputProps: {'data-autoid': `txtCompanyUserName`},
                  labelWidth: 20,
                }}
                value={orgUserName}
                onChange={e => setOrgUserName(e.target.value)}
                className={`!mb-4`}
              />
              <STextField
                id="password"
                name="password"
                label="Password"
                v2
                value={password}
                variant={'outlined'}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  inputProps: {'data-autoid': `txtCompanyPassword`},
                }}
                fullWidth
              />
              <PrimaryButton
                label={'Login'}
                fullWidth
                isSubmitting={isOrgLoading}
                disabled={isOrgLoading}
                className="!mt-6"
                width="100%"
                onClick={() => {
                  submitMyobOrg();
                }}
                data-autoid="btnLogin"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isNextButtonDisable =
    !selectedAccount ||
    !isAuthorized ||
    !(
      purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.SUCCESSFUL ||
      purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.FAILED
    ) ||
    isLoading ||
    isOrgLoading;

  return (
    <div className={`wrapper relative h-full w-full overflow-hidden bg-white pb-16`}>
      {!accounts?.length ? (
        <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
      ) : (
        <div className={`mx-auto max-w-[540px] align-middle`}>
          {!selectedAccount ? (
            <>
              {isPsblOnboarding ? (
                <>
                  <p className={`mx-auto mt-4 text-left font-poppins text-2xl font-light text-[#333]`}>
                    Great! Let’s integrate your financial system
                  </p>
                  <p className={`mx-auto my-4 text-left text-sm font-normal text-[#999999]`}>
                    Please select and authorise your financial system and click ‘Next’ to continue.
                  </p>
                </>
              ) : (
                <p className={`mx-auto mt-4 text-left font-poppins text-[24px] font-light text-[#121212]`}>
                  Now, let’s integrate your accounting system
                </p>
              )}
              <div>
                {accounts &&
                  (accounts.length > 1 ? (
                    <div>
                      <div className="flex justify-center gap-x-5">
                        {accounts.map((account, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className={`account-box flex flex-col items-center justify-center`}>
                                {renderAdaptorImage(account)}
                                <Button
                                  onClick={() => {
                                    setSelectedAdaptorId(account.MasterAdaptorID);
                                    onSelectAccountingSystem(account);
                                  }}
                                  disabled={isLoading}
                                  loading={isLoading && selectedAdaptorId === account.MasterAdaptorID}
                                  className={'hsl(var(--primary)) mt-3 bg-white'}
                                  data-autoid={`btnSelected-${account.Name}`}
                                  variant="outlined"
                                >
                                  Select
                                </Button>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {isPsblOnboarding && lmiCommercialRelease92671 && (
                        <div className="mt-6">
                          <Typography variant="small">
                            If you are unable to integrate your financial system online or would prefer to complete the
                            onboarding process with our support team, please click the 'Live Chat' option below.
                            Otherwise, please select your financial system so we can import your chart of accounts.{' '}
                          </Typography>
                          <Typography variant="small" className="mt-2">
                            This is also a great time to ensure your financial system is up to date and had no duplicate
                            Suppliers before importing them into Spenda.
                          </Typography>
                        </div>
                      )}
                    </div>
                  ) : (
                    <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
                  ))}
              </div>
            </>
          ) : selectedAccount && showSelectOrganisations ? (
            renderMyOBOrgSelection()
          ) : selectedAccount && !showSelectOrganisations ? (
            <div className="wrapper flex h-full flex-col justify-between pb-24">
              <div className="overflow-y-auto">
                <p className={`mx-auto mt-4 text-center font-poppins text-[24px] font-light text-[#121212]`}>
                  {isAuthorized
                    ? purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.SUCCESSFUL
                      ? 'Great, your accounting software is now connected to Spenda'
                      : 'Let’s sync your accounts payable ledger'
                    : 'Please authorise the connection'}
                </p>
                <div className={`mt-5 flex items-center justify-center`}>
                  <React.Fragment>
                    <div className={`account-box flex flex-col items-center justify-center`}>
                      {renderAdaptorImage(selectedAccount)}
                      <Button
                        onClick={() => synkConnect()}
                        disabled={isLoading || isAuthorized}
                        loading={isLoading || isOrgLoading}
                        className={'hsl(var(--primary)) mt mt-3 bg-white'}
                        data-autoid={`btnAuthorise-${financialAdaptorName}`}
                        variant="outlined"
                      >
                        {isAuthorized ? 'Authorised' : 'Authorise'}
                      </Button>
                    </div>
                  </React.Fragment>
                </div>
                <div className={`mb-5 mt-8 flex justify-center`}>
                  {isAuthorized &&
                    purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.NOTSTARTED &&
                    organizationName && (
                      <PrimaryButton
                        label={'Connect Accounts Payable'}
                        isSubmitting={isLoading}
                        disabled={isLoading}
                        onClick={() => {
                          getPurchaseInvoices();
                        }}
                        data-autoid="btnGetAccountsPayable"
                      />
                    )}
                  {purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.SUCCESSFUL && (
                    <p id="simple-modal-description" className={`px-7 pb-16 text-center text-base`}>
                      We have now imported your accounts payable ledger into Spenda. Press ‘Next’ to continue.
                    </p>
                  )}
                  {purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.STARTED && (
                    <ProgressBar bgcolor={'hsl(var(--primary))'} completed={purchaseInvoiceRequestPercentage} />
                  )}
                  {purchaseInvoiceRequestStatus === FetchAdaptorDataStatus.FAILED && (
                    <p id="simple-modal-description" className={`px-7 pt-7 text-center text-base`}>
                      Fetching Accounts Payable is taking some time! While it gets completed, you can continue your
                      onboarding by clicking on ‘Next’
                    </p>
                  )}
                </div>
              </div>
              {isPsblOnboarding && psblV22LowTouchOnboarding92923 && <ResponsiveBookOnboardingButton />}
            </div>
          ) : (
            <LoadingIndicator isLoading={true} size="md" color="hsl(var(--primary))" />
          )}
          {isPsblOnboarding ? (
            <PSBLOnboardingFooter
              isChatWidget
              isLoading={isLoading}
              isDisabled={isNextButtonDisable}
              onPrimaryClick={handleNext}
            />
          ) : (
            <APFooter
              onNextClick={() => {
                handleNext();
              }}
              buttonLabel={'Next'}
              isAPOnboardingV1={props.isAPOnboardingV1}
              isCancelButton
              secondaryButtonLabel="Skip"
              secondaryButtonDisable={isAuthorized || showSelectOrganisations || isOrgLoading || isLoading}
              handleCancelClick={() => {
                setIsSkipAuthorization(true);
              }}
              isDisabled={isNextButtonDisable}
            />
          )}
        </div>
      )}
      {isSkipAuthorization && (
        <SkipAutorisationModal
          isAPOnboardingV1={props.isAPOnboardingV1}
          handleSkip={() => {
            setIsSkipAuthorization(false);
            handleSkipAuthorization();
          }}
          handleClose={() => setIsSkipAuthorization(false)}
        />
      )}
    </div>
  );
};
