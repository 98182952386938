import React from 'react';
import {APFooter} from '.././APFooter';
import clsx from 'clsx';
import {ARDialogTemplate} from '../../AccountsReceivable/ARDialogTemplate';

export interface ISetupCompleteProps {
  onSetupComplete: () => void;
  isAPOnboardingV1?: boolean;
  isLoading?: boolean;
  isPsblOnboarding?: boolean;
}

export const SetupComplete = (props: ISetupCompleteProps) => {
  return (
    <ARDialogTemplate
      dialogProps={{size: 'xs', open: true, className: 'sm:!max-w-[565px] sm:!w-[565px] sm:!min-w-[565px]'}}
      bodyClass={clsx(!props?.isPsblOnboarding ? `!mx-0 !py-0 sm:!max-w-[565px]` : '!mx-0')}
      dialogContentClass={clsx(!props?.isPsblOnboarding && `!py-0`)}
      isFullScreen
      body={
        <div
          className={clsx(
            `!relative !flex sm:max-w-[565px] `,
            props?.isPsblOnboarding ? 'sm:!h-[412px]' : 'sm:!h-[370px]',
          )}
        >
          <div className={`relative flex h-[80%] flex-row items-center justify-center px-10 pb-16 font-poppins `}>
            <div className={clsx(props?.isPsblOnboarding ? 'mt-3 w-[97%] text-black-800' : 'w-10/12 text-primary')}>
              <h2 className="pb-8 text-center text-[20px] sm:text-2xl">Great! You're all set.</h2>
              <p className={clsx(`my-3  text-center text-sm font-medium sm:text-base`)}>
                {props?.isPsblOnboarding
                  ? `Click 'Done' to start using Spenda, and and if you need more help along the way, just click on ‘Live Chat’ at any time.`
                  : 'Your accounts payable setup is now complete. Click ‘Done’ to start using Spenda.'}
              </p>
            </div>
          </div>

          <APFooter
            onNextClick={() => props.onSetupComplete()}
            buttonLabel={'Done'}
            isDisabled={props.isLoading}
            isLoading={props.isLoading}
            isAPOnboardingV1={props.isAPOnboardingV1}
            isSetupComplete={true}
            isPsblOnboarding={props?.isPsblOnboarding}
          />
        </div>
      }
    />
  );
};
