import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Radio, Select, Switch, Typography, Option, Button, Dialog} from 'spenda-ui-react';
import {ArRefreshBtn} from '../../../components/arRefresh/ArRefresh';
import {DatTypes} from '../../../model/constants/Constants';
import {Inventory} from '../../../model/inventory/Inventory';
import {useInventoryAPI} from '../../../services/useInventoryAPI';
import {IPagedActionResults} from '../../../model/ActionResults';
import Creditline from '../../../assets/png/AccountsPayable/Creditline.png';
import {CloseCross} from '../../../assets/svg/CloseCross';
import {PostingTypeModal} from '../../../components/accountsPayableOnboarding/PostingTypeModal';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {useSupplierAPI} from '../../../services/useSupplierAPI';
import {ISupplier} from '../../../model/supplier/Supplier';
import {ISupplierPrimaryContact} from '../../../model/requires-attention/StagedTransactions';
import clsx from 'clsx';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {SelectCOADropdown} from '../../../components/AccountsReceivable/required-attention/SelectCOADropdown';
import {IntegrationContext} from '../../../context/IntegrationContext';
import PSBLOnboardingFooter from '../../../components/buttons/PSBLOnboardingFooter';

interface IPostingConfigurationViewProps {
  isPsblOnboarding?: boolean;
  supplierId?: number;
  onComplete?: () => void;
  onClickCancelBtn?: () => void;
}

export const PostingConfigurationView = (props: IPostingConfigurationViewProps) => {
  // Props
  const {supplierId, onComplete, onClickCancelBtn, isPsblOnboarding} = props;

  // State
  const [isVendorMappingEnable, setIsVendorMappingEnable] = useState<boolean>(false);
  const [selectedPostingInventory, setSelectedPostingInventory] = useState<string>();
  const [selectedCreditline, setSelectedCreditline] = useState<string>();
  const [postingSupplierDetails, setPostingSupplierDetails] = useState<ISupplier>();
  const [inventoryList, setInventoryList] = useState<Partial<Inventory>[]>([]);
  const [tradeAccountCheckbox, setTradeAccountCheckbox] = useState(true);
  const [previewImage, setPreviewImage] = useState<boolean>(false);
  const [isShowPostingTypeModal, setIsShowPostingTypeModal] = useState(false);
  const [refetchCOACount, setRefetchCOACount] = useState(0);

  // APIs
  const {listInventory} = useInventoryAPI();
  const {getSupplierById, isLoading} = useSupplierAPI();
  const {saveSupplier, isLoading: isSaveBtnLoading} = useSupplierAPI();

  // Context
  const {financialAdaptorDisplayName, isMYOBAdaptor, isQuickBooksAdaptor, financialAdaptor} =
    useContext(IntegrationContext);

  // Constant
  const {IsVendorMappingEnabled, PostingInventoryID, CreditLineAccountID, PostingSupplierID} =
    postingSupplierDetails || {};
  const isMyobOrQuickBooks = isMYOBAdaptor || isQuickBooksAdaptor;

  // FeatureFlags
  const {PSBLSTAGING, psblV22LowTouchOnboarding92923} = useFeatureFlags().supplierDriven();

  useEffect(() => {
    getInventoryList();
    fetchPostinConfigurationDetails();
  }, [supplierId]);

  useEffect(() => {
    setIsVendorMappingEnable(Boolean(IsVendorMappingEnabled));
    setSelectedPostingInventory(PostingInventoryID ? String(PostingInventoryID) : undefined);
    setSelectedCreditline(CreditLineAccountID ? String(CreditLineAccountID) : undefined);
  }, [postingSupplierDetails]);

  const fetchData = async () => {
    setRefetchCOACount(prev => prev + 1);
    getInventoryList();
    fetchPostinConfigurationDetails();
  };

  const getInventoryList = () => {
    try {
      if (!PSBLSTAGING) {
        listInventory({isPurchased: true, isSystem: false}).then((res: IPagedActionResults<Inventory[]>) => {
          setInventoryList(res?.Value);
        });
      }
    } catch {}
  };

  const fetchPostinConfigurationDetails = async () => {
    try {
      if (!supplierId) return;
      setIsShowPostingTypeModal(false);
      const response = await getSupplierById(supplierId);
      setPostingSupplierDetails(response);
    } catch {}
  };

  const handleSave = async (): Promise<void> => {
    if (!supplierId) return;
    await saveSupplier({
      ...postingSupplierDetails,
      IsVendorMappingEnabled: isVendorMappingEnable,
      PostingInventoryID: !PSBLSTAGING ? selectedPostingInventory : postingSupplierDetails?.PostingInventoryID,
      CreditLineAccountID: Number(selectedCreditline) || null,
    });
    if (isPsblOnboarding) {
      onComplete?.();
      return;
    }
    await fetchPostinConfigurationDetails();
  };

  const handlePostingTypeModalDoneClick = async (mergeWithSupplier: boolean) => {
    if (mergeWithSupplier) return;
    fetchData();
  };

  const inventoryOptions = useMemo(
    () =>
      inventoryList?.map(({ID, InventoryCode, ShortDescription}) => ({
        optionName: `${InventoryCode || ''} ${ShortDescription || ''}`,
        value: ID?.toString(),
      })),
    [inventoryList, selectedPostingInventory],
  );

  const isButtonActive = useMemo(() => {
    return selectedPostingInventory || (isVendorMappingEnable && selectedCreditline);
  }, [selectedPostingInventory, isVendorMappingEnable, selectedCreditline]);

  let supplierDetails: ISupplierPrimaryContact | undefined = undefined;
  if (postingSupplierDetails) {
    supplierDetails = {
      abn: postingSupplierDetails?.ABN,
      emailAddress: postingSupplierDetails?.PrimaryContactEmailAddress,
      firstName: postingSupplierDetails?.PrimaryContactFirstName,
      lastName: postingSupplierDetails?.PrimaryContactLastName,
      name: postingSupplierDetails?.Name,
      phone: postingSupplierDetails?.Phone1,
      phoneMobile: postingSupplierDetails?.PrimaryContactMobile,
      refNumber: postingSupplierDetails?.RefNumber,
      supplierID: postingSupplierDetails?.PostingSupplierID || supplierId || 0,
      countDebitNotes: 0,
      countPurchaseInvoices: 0,
      IsVendorMappingEnabled: postingSupplierDetails?.IsVendorMappingEnabled,
    };
  }

  return (
    <div className={`wrapper relative w-full overflow-hidden bg-white pb-16`}>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} size="md" />
      ) : (
        <>
          <div
            className={` header flex !min-h-[60px] flex-col justify-between border-b border-[#D8D8D8] py-2.5 sm:flex-row sm:items-center`}
          >
            <Typography
              className=" font-poppins text-[20px] font-medium text-[#333333] sm:text-xl sm:font-light"
              variant="h2"
            >
              Posting Configurations
            </Typography>
            <div className="mr-3 mt-3 flex flex-row items-center justify-start sm:mt-0 sm:min-w-[380px] sm:justify-end">
              <ArRefreshBtn
                newOnly
                title="Refresh Clearing Accounts"
                datTypeId={DatTypes.Accounts}
                dataAutoID={`btnClearingAccounts-${financialAdaptor?.Name}`}
                handleRefreshData={() => setRefetchCOACount(prev => prev + 1)}
              />
            </div>
          </div>
          <div className={`body mt-6 flex w-full overflow-y-auto bg-white`}>
            <div className="flex h-[calc(100vh-335px)] w-full flex-col items-start justify-start overflow-y-auto">
              <div
                className={` flex w-full flex-col justify-between rounded-[6px] bg-[#F6F6F6] p-2.5 sm:w-[550px] sm:min-w-[544px] sm:flex-row sm:items-center sm:p-5`}
              >
                <div>
                  <Typography variant="paragraph" className="font-bold">
                    Supplier
                  </Typography>
                  <Typography variant="paragraph" className="font-medium" data-autoid={`lblSupplier`}>
                    {postingSupplierDetails?.Name || '-'}
                  </Typography>
                </div>
                <div>
                  <Typography variant="paragraph" className="font-bold">
                    Reference ID
                  </Typography>
                  <Typography variant="paragraph" className="font-medium" data-autoid={`lblRefId`}>
                    {postingSupplierDetails?.RefNumber || '-'}
                  </Typography>
                </div>
                <div>
                  <Typography variant="paragraph" className="font-bold">
                    ABN
                  </Typography>
                  <Typography variant="paragraph" className="font-medium" data-autoid={`lblABN`}>
                    {postingSupplierDetails?.ABN || '-'}
                  </Typography>
                </div>
              </div>
              {!psblV22LowTouchOnboarding92923 && (
                <div className="mt-5 w-full text-primary sm:!w-[380px]">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="h-[44px] w-full"
                    disabled={Boolean(PostingSupplierID)}
                    onClick={() => setIsShowPostingTypeModal(true)}
                    data-autoid={`btnMapToSupplier`}
                  >
                    Map Capricorn to a Supplier in your Financials
                  </Button>
                  <Typography variant="xsmall" className="ml-2 mt-1 text-black-800">
                    This is a mandatory requirement
                  </Typography>
                </div>
              )}
              {!PSBLSTAGING && (
                <>
                  <div className="ml-2 mt-4">
                    <Switch
                      checked={isVendorMappingEnable || false}
                      disabled={IsVendorMappingEnabled || !PostingSupplierID}
                      color="success"
                      label={
                        <div className="opacity-100">
                          <Typography color="blue-gray" className="gap-3 font-medium">
                            Are you using a trade account?
                          </Typography>
                        </div>
                      }
                      onChange={() => setIsVendorMappingEnable(!isVendorMappingEnable)}
                      data-autoid={`chkLinkOrignialVendor`}
                    />
                  </div>
                  <div className="mt-[30px] flex w-full flex-col gap-6 sm:!w-[440px]">
                    <Select
                      value={selectedPostingInventory}
                      disabled={!PostingSupplierID}
                      onChange={value => value && setSelectedPostingInventory(value)}
                      color="primary"
                      size="lg"
                      variant="outlined"
                      label="Map purchases from Capricorn to an inventory account"
                      placeholder="Map purchases from Capricorn to an inventory account"
                      name="inventoryMappingdropdown"
                      labelProps={{className: '!text-ellipsis whitespace-nowrap overflow-hidden'}}
                      selected={() => inventoryOptions?.find(c => c?.value === selectedPostingInventory)?.optionName}
                    >
                      {!inventoryOptions?.length ? (
                        <Option disabled key={'noResults'}>
                          No results
                        </Option>
                      ) : (
                        inventoryOptions?.map(({optionName, value}) => (
                          <Option key={value} value={value}>
                            {optionName}
                          </Option>
                        ))
                      )}
                    </Select>
                  </div>
                </>
              )}
              {!psblV22LowTouchOnboarding92923 && (
                <div className="my-[35px] flex justify-between gap-5">
                  <Typography variant="paragraph" color="black">
                    Do you already have a trade account in {financialAdaptorDisplayName}?
                  </Typography>
                  <div className="-my-4 flex justify-end">
                    <Radio
                      color="primary"
                      name="type"
                      label="Yes"
                      checked={tradeAccountCheckbox}
                      onClick={() => setTradeAccountCheckbox(true)}
                      disabled={!isVendorMappingEnable || !PostingSupplierID}
                    />
                    <Radio
                      color="primary"
                      name="type"
                      label="No"
                      checked={!tradeAccountCheckbox}
                      onClick={() => setTradeAccountCheckbox(false)}
                      disabled={!isVendorMappingEnable || !PostingSupplierID}
                    />
                  </div>
                </div>
              )}
              {tradeAccountCheckbox ? (
                <div
                  className={clsx(
                    'flex !h-[60px] w-full flex-col gap-6 sm:!w-[440px]',
                    psblV22LowTouchOnboarding92923 && 'mt-6',
                  )}
                >
                  <SelectCOADropdown
                    key={'selectCreditLineAccount'}
                    isMyobOrQuickBooks={isMyobOrQuickBooks}
                    refetchcount={refetchCOACount}
                    value={selectedCreditline}
                    onChange={value => value && setSelectedCreditline(value)}
                    name={'liabilityAccount'}
                    color="primary"
                    size="lg"
                    variant="outlined"
                    label="Map Capricorn to your trade account"
                    placeholder="Map Capricorn to your trade account"
                    disabled={
                      !isVendorMappingEnable ||
                      !PostingSupplierID ||
                      (Boolean(CreditLineAccountID) && psblV22LowTouchOnboarding92923)
                    }
                  />
                  <Typography variant="xsmall" color="blue-gray" className=" flex-start -my-5 ml-2 flex font-normal">
                    Please review this with your accountant or bookkeeper.
                  </Typography>
                </div>
              ) : (
                isVendorMappingEnable && (
                  <>
                    <div className="mt-2 h-auto w-full rounded-[10px] bg-[#F4E8D1] px-2.5 py-2.5 font-poppins text-xs sm:h-[77px] sm:w-[550px] sm:px-[14px]">
                      <p className="font-medium">Why creditline is important?</p>
                      <p className="font-norma mt-1">
                        Creditline is important because Create New clearing account in {financialAdaptorDisplayName}.
                        Select Account type as liability account, Select Account type as liability account.
                      </p>
                    </div>
                    <div className="mb-4 flex w-full flex-col justify-between sm:w-[550px] sm:flex-row sm:items-center">
                      <div>
                        <p className="pt-4 text-base font-medium text-[#666666]">Steps to setup creditline accout:</p>
                        <ul>
                          <li className="items-cente flex justify-start pl-2 pt-3">
                            <p className="text-xs text-spenda-labeltext">
                              &#8226; &nbsp;Create New clearing account in {financialAdaptorDisplayName}
                            </p>
                          </li>
                          <li className="flex items-center justify-start pl-2  pt-1">
                            <p className="text-xs text-spenda-labeltext">
                              &#8226; &nbsp;Select Account type as liability account
                            </p>
                          </li>
                          <li className="flex items-center justify-start pl-2 pt-1">
                            <p className="text-xs text-spenda-labeltext"> &#8226; &nbsp;Set tax as BAS excluded</p>
                          </li>
                          <li className="flex items-center justify-start pl-2 pt-1">
                            <p className="text-xs text-spenda-labeltext">
                              &#8226; &nbsp;Select option "Enable payment to this account"
                            </p>
                          </li>
                          <li className="flex items-center justify-start pl-2 pt-1">
                            <p className="text-xs text-spenda-labeltext">
                              &#8226; &nbsp;Once setup is done in {financialAdaptorDisplayName}, select your creditline
                              account
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div className="mt-3 self-center sm:mr-4 sm:mt-0">
                        <img src={Creditline} alt="" width="176px" height="130px" />
                        <span
                          className="mt-1 flex cursor-pointer items-center justify-center text-xs font-semibold text-primary"
                          onClick={() => setPreviewImage(true)}
                        >
                          Preview
                        </span>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <Button
                        variant="outlined"
                        color="primary"
                        className="!h-[44px] w-full sm:!w-[380px] "
                        data-autoid="btnHasCreditlineSetup"
                        onClick={() => setTradeAccountCheckbox(true)}
                      >
                        I Have Setup My Creditline Account
                      </Button>
                    </div>
                  </>
                )
              )}
            </div>
          </div>
          {isPsblOnboarding ? (
            <PSBLOnboardingFooter
              isCancel
              onCancelClick={() => onClickCancelBtn?.()}
              onPrimaryClick={() => handleSave()}
              primaryLabel="Save"
              isDisabled={!isButtonActive}
              isLoading={isSaveBtnLoading}
            />
          ) : (
            <div
              className={clsx(
                `footer absolute flex h-[60px] w-full flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-4`,
                !isPsblOnboarding ? 'bottom-[50px]' : 'bottom-2.5',
              )}
            >
              <Button
                onClick={() => onClickCancelBtn?.()}
                data-autoid={`btnCancel`}
                variant="outlined"
                color="primary"
                className="bg-white"
              >
                Cancel
              </Button>
              <Button
                data-autoid={`btnSave`}
                disabled={!isButtonActive}
                variant="filled"
                color="primary"
                loading={isSaveBtnLoading}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </div>
          )}
          {isShowPostingTypeModal && (
            <PostingTypeModal
              supplierDetails={supplierDetails}
              handleClose={() => setIsShowPostingTypeModal(false)}
              handleDone={handlePostingTypeModalDoneClick}
              scope="PSBL"
              isDialog
            />
          )}
          {previewImage && (
            <Dialog open handler={() => setPreviewImage(false)} size="xxl">
              <div className="flex items-center justify-center" onClick={() => setPreviewImage(false)}>
                <CloseCross
                  data-autoid="btnClose"
                  onClick={() => setPreviewImage(false)}
                  className="absolute right-4 top-4 cursor-pointer text-primary"
                />
                <img src={Creditline} style={{height: '100vh'}} />
              </div>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};
