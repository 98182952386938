import React from 'react';
import {Button} from 'spenda-ui-react';
import {isProductionEnv} from '../../config';
import {ChatWidget} from '../onboarding/ChatWidget';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';

interface IPSBLOnboardingFooterProps {
  isLoading?: boolean;
  isChatWidget?: boolean;
  isCancel?: boolean;
  onCancelClick?: () => void;
  isDisabled?: boolean;
  onPrimaryClick?: () => void | Promise<void>;
  primaryLabel?: string;
  hideBookOnboardingButton?: boolean;
}
const PSBLOnboardingFooter = (props: IPSBLOnboardingFooterProps) => {
  const {
    isDisabled,
    isChatWidget,
    isCancel,
    primaryLabel,
    onPrimaryClick,
    onCancelClick,
    isLoading,
    hideBookOnboardingButton,
  } = props;

  //  Flag
  const {psblV22LowTouchOnboarding92923} = useFeatureFlags().supplierDriven();

  return (
    <div
      className={`absolute bottom-0 left-0 z-[99] flex h-[86px] w-full flex-row items-center justify-between gap-2 border-t border-[#ddd] bg-white shadow-[0_0px_1px_0px_rgba(0,0,0,0.1)] sm:bottom-0 sm:h-[52px] sm:gap-2.5 sm:rounded-[6px] sm:border-t-0 sm:bg-[#EFEFEF] sm:px-2.5 sm:shadow-none`}
    >
      {isChatWidget && <ChatWidget isAPOnboarding={true} />}
      {isCancel && (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className="h-[48px] w-full rounded-[10px] bg-white sm:h-[40px] sm:rounded-md"
            data-autoid={`btnSkip`}
            onClick={onCancelClick}
            loading={false}
          >
            Cancel
          </Button>
        </div>
      )}
      <div className={`flex w-full flex-row items-end gap-2 sm:w-auto sm:gap-2.5`}>
        {!hideBookOnboardingButton && psblV22LowTouchOnboarding92923 && (
          <Button
            variant="filled"
            color="primary"
            className="hidden h-[48px] !w-full !min-w-[240px] rounded-[10px] sm:block sm:h-[40px] sm:rounded-md"
            data-autoid={`btnBookOnboarding`}
            onClick={handleBookOnboardingClick}
          >
            Book Assisted Onboarding
          </Button>
        )}
        {onPrimaryClick && (
          <Button
            variant="filled"
            className="h-[48px] w-full rounded-[10px] sm:h-[40px] sm:rounded-md"
            color="primary"
            data-autoid={`btnNext`}
            onClick={onPrimaryClick}
            disabled={isDisabled}
            loading={isLoading}
          >
            {primaryLabel || 'Next'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PSBLOnboardingFooter;

export const ResponsiveBookOnboardingButton = () => {
  return (
    <div className="flex w-full flex-col items-center justify-between rounded-[6px] bg-primary-header p-2.5 sm:hidden">
      <p className="mb-3 text-[9px] font-semibold text-black">
        To book an assisted onboarding experience and get set up with ease with one of our Customer Success team
        members, please click the button below.
      </p>
      <Button
        variant="filled"
        color="primary"
        className="block h-[48px] !w-full !min-w-[240px] rounded-[10px] sm:hidden sm:h-[40px] sm:rounded-md"
        data-autoid={`btnBookOnboarding`}
        onClick={handleBookOnboardingClick}
      >
        Book Assisted Onboarding
      </Button>
    </div>
  );
};

const handleBookOnboardingClick = () => {
  const bookingURL = `https://outlook.office365.com/book/StatementIntegrationBookingForm@theappstablishment.onmicrosoft.com/`;
  window.open(isProductionEnv ? bookingURL : 'https://spenda.co', '_blank');
};
