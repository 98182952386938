import React, {useEffect, useState} from 'react';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {ISupplier} from '../../model/supplier/Supplier';
import {ISupplierPrimaryContact} from '../../model/requires-attention/StagedTransactions';
import {PostingTypeModal} from '../accountsPayableOnboarding/PostingTypeModal';
import {useTabs} from 'spenda-ui-react';
import {LowTouchPsblOnboardingSteps} from '../../model/constants/Constants';
import {ImportWorkflowsModal} from '../AccountsReceivable/modals/ImportWorkflowsModal';
import {ISupplierRefreshResponse} from '../../services/useSupplierTransactionsAPI';

interface ICapricornSupplierMappingProps {
  supplierId?: number;
  handleNext?: () => void;
  suppliersImportStatusWrokFlowId: number | undefined;
  startSuppliersImportWorkflow: (params: {supplier?: boolean; apDataV2?: boolean}) => Promise<{
    [key: string]: ISupplierRefreshResponse;
  }>;
}

const CapricornSupplierMapping = (props: ICapricornSupplierMappingProps) => {
  // Props
  const {handleNext, supplierId, suppliersImportStatusWrokFlowId, startSuppliersImportWorkflow} = props;

  // State
  const [postingSupplierDetails, setPostingSupplierDetails] = useState<ISupplier>();
  const [isShowImportModal, setIsShowImportModal] = useState(false);
  const {state: tabState} = useTabs();

  // APIs
  const {getSupplierById} = useSupplierAPI();

  useEffect(() => {
    fetchPostinConfigurationDetails();
  }, [supplierId]);

  useEffect(() => {
    if (tabState?.active === LowTouchPsblOnboardingSteps.SupplierMapping) {
      checkAndStartSupplierImportWorkflow();
    }
  }, [tabState?.active, suppliersImportStatusWrokFlowId]);

  const checkAndStartSupplierImportWorkflow = async () => {
    try {
      setIsShowImportModal(true);
      if (suppliersImportStatusWrokFlowId) return;
      startSuppliersImportWorkflow({supplier: true, apDataV2: true});
    } catch {}
  };

  const fetchPostinConfigurationDetails = async () => {
    try {
      if (!supplierId) return;
      const response = await getSupplierById(supplierId);
      setPostingSupplierDetails(response);
    } catch {}
  };

  const handleSave = async (): Promise<void> => {
    // TODO: We'll confirm about this API call
    await fetchPostinConfigurationDetails();
    handleNext?.();
  };

  let supplierDetails: ISupplierPrimaryContact | undefined = undefined;
  if (postingSupplierDetails) {
    supplierDetails = {
      abn: postingSupplierDetails?.ABN,
      emailAddress: postingSupplierDetails?.PrimaryContactEmailAddress,
      firstName: postingSupplierDetails?.PrimaryContactFirstName,
      lastName: postingSupplierDetails?.PrimaryContactLastName,
      name: postingSupplierDetails?.Name,
      phone: postingSupplierDetails?.Phone1,
      phoneMobile: postingSupplierDetails?.PrimaryContactMobile,
      refNumber: postingSupplierDetails?.RefNumber,
      supplierID: postingSupplierDetails?.PostingSupplierID || supplierId || 0,
      countDebitNotes: 0,
      countPurchaseInvoices: 0,
      IsVendorMappingEnabled: postingSupplierDetails?.IsVendorMappingEnabled,
    };
  }

  return (
    <>
      {isShowImportModal ? (
        <ImportWorkflowsModal
          importWorkflowId={suppliersImportStatusWrokFlowId}
          open={isShowImportModal}
          onSuccess={() => setIsShowImportModal(false)}
          message="This might take a while. If you like, you can move on to the next step and we will let you know when it’s done."
        />
      ) : (
        <div className="wrapper relative h-full w-full overflow-hidden bg-white pb-16">
          <div className="mx-2 h-full w-full overflow-y-auto overflow-x-hidden pb-0 sm:mx-10 sm:pb-0">
            <div className={`h-full !max-w-[747px] align-middle`}>
              <p
                className={`!pt-4 text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}
              >
                Supplier Mapping
              </p>
              <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
                To start integrating transactions, Spenda needs to know which Supplier record to use in your financial
                system. Please select [Capricorn] from the list below to complete the setup. Alternatively, you can
                create a new Supplier record for Capricorn.
              </p>
              <PostingTypeModal
                supplierDetails={supplierDetails}
                scope="PSBL"
                isDialog={false}
                handleClose={() => {}}
                handleDone={handleSave}
                isLowTouchOnboarding
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CapricornSupplierMapping;
